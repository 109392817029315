@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

:root,
body {
  height: 100%;
}

#root {
  display: contents;
}

body {
  margin: 0;
  padding: 0;
}
